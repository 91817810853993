import React from "react";

function Topics(props) {
  const { rName } = props;
  const url = typeof window !== 'undefined' ? window.location.href : '';



  return (
    <p>Topics! {url}</p>
  )
}
export default Topics
